.teamContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.teamName {
  padding-top: 5px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}

.points {
  font-size: 40px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 2px;
  border-color: white;
}
