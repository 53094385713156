.cardHeaderBg {
  background-image: url('./../../../assets/images/matchbg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.cardHeaderContent {
  text-align: center;
  padding: 10px;
  /* background: rgba(165, 42, 42, 0.8); Black background with opacity */
  color: white;
  font-size: 34px;
  font-family: 'Oswald';
}

.title {
  font-size: 32px;
}

.countDown {
  /* padding-bottom: 10px; */
}

.match {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardTitle {
  text-align: center;
}

.dateTime {
  display: flex;
  font-size: medium;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
}