.countryChip {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
}

a:link {
  text-decoration: none;
  color: inherit;
  cursor: auto;
}

a:visited {
  text-decoration: none;
  color: inherit;
  cursor: auto;
}