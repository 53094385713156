.dateCard {
  background-color: brown;
  color: white;
  width: 75px;
}

.dateCardHeader {
  background-color: white;
  padding: 5px 0px 5px 0px;
}

.dateCardSubtitle {
  color: brown;
}

.hour {
  padding: 4px;
}