ion-grid {
  --ion-grid-padding: 0px;
}

ion-col {
  --ion-grid-column-padding: 0px;
}

ion-thumbnail {
  width: 75px;
  padding-top: 5px;
  --border-radius: 5px;
}